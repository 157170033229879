import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let flash_key = this.data.get("key");
    let flash_value = this.data.get("value");

    toastr.options = {
      debug: false,
      positionClass: "toastr-top-center",
      onclick: null,
      fadeIn: 300,
      fadeOut: 1000,
      timeOut: 3000
    };

    switch (flash_key) {
      case "notice":
        toastr.success(flash_value);
        break;
      case "alert":
        toastr.error(flash_value);
        break;
      default:
        toastr.success(flash_value);
    }

    this.element.remove()
  }
}