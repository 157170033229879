import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ["container", "header", "name", "batch_no", "dose_number", "exp_date", "date", "reminder", "dose_date"]

	connect() {
		this.checkDoseComplete()
	}

	checkDoseComplete() {
		for (let i = 0; i < this.containerTargets.length; i++) {
			const elements = [this.nameTargets, this.batch_noTargets, this.dose_numberTargets, this.exp_dateTargets]
			const completed = !elements.map(element => element[i].value !== "").includes(false)
			if (completed) {
				this.headerTargets[i].innerHTML = `(${this.nameTargets[i].value})`
				this.reminderTargets[i].classList.remove('d-flex')
				// for the first dose, when dose completed, update dose_date field to the current date if it is empty
				const date = this.dose_dateTargets[i].value === "" ? moment(new Date()).format('DD-MM-YYYY') : this.dose_dateTargets[i].value
				this.dateTargets[i].innerHTML = i === 0 ? date : this.dose_dateTargets[i].value
				this.dateTargets[i].hidden = false
				this.reminderTargets[i].hidden = true

				this.saveFirstDose(date, i)
			} else {
				this.headerTargets[i].innerHTML = "(Planned)"
				this.dateTargets[i].hidden = true
				this.reminderTargets[i].classList.add('d-flex')
				this.reminderTargets[i].hidden = false
			}
		}
	}

	saveFirstDose(date, index) {
		if (index === 0) {
			const url = this.element.dataset.url
			Rails.ajax({
				type: 'PUT',
				url: url,
				data: `vaccination[index]=0&vaccination[field]=dose_date&vaccination[value]=${date}`
			})
		}
	}
}
