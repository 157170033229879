import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dependency"];

  connect() {
    if (!this.dependencyTargets.length) return;

    this.toggleDependencies();
  }

  toggleDependencies() {
    this.dependencyTargets.forEach((dependency) => {
      if (!dependency.dataset.parentFieldName) return;
      const ancestorElements = [];
      dependency.dataset.parentFieldName.split(",").forEach((parentFieldName) => {
        const parentElement = document.querySelector(`[data-field-name="${parentFieldName}"]`);
        if (parentElement) {
          ancestorElements.push(parentElement.checked.toString() === dependency.dataset.parentFieldValue);
        }
      });
      if (ancestorElements.length && ancestorElements.every((ancestorElement) => ancestorElement)) {
        dependency.style.display = "";
      } else {
        dependency.style.display = "none";
      }
    });
  }

  toggle(_event) {
    this.toggleDependencies();
  }
}
