import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = [ "check", "passwd"]

  checkPasswd(event) {
     fetch(`${event.target.dataset.url}/?password=${window.btoa(this.passwdTarget.value)}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
      },
    })
    .then(response => response.text())
    .then(data => {
      const entropy = JSON.parse(data).entropy;
      this.updateCSS(entropy);
    })
  }

  updateCSS(entropy) {
    this.clearCSS()
    if(entropy < 16) {
      this.checkTarget.classList.add("hidden")
    } else if(entropy < 20) {
      this.checkTarget.classList.add("medium")
    } else {
      this.checkTarget.classList.add("good")
    }
  }

  clearCSS() {
    this.checkTarget.classList.remove("good")
    this.checkTarget.classList.remove("medium")
    this.checkTarget.classList.remove("hidden")
  }
}
