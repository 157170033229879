import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["toggles", "confirmed", "checkbox", "yesRadio", "noRadio"]
  static values = { noRadioArray: { type: Array, default: [] }, yesRadioArray: { type: Array, default: [] } }

  connect() {
    const criteriaList = this.element.dataset.eligibilityCriteriaList.split(" ")
    this.validYesSelect = this.element.dataset.eligibilityValidYes.split(" ")
    this.noCount = criteriaList.length - this.validYesSelect.length
  }

  checkEligibility(event) {
    this.elementErrorId = event.currentTarget.dataset.errorId;
    this.elementIconId = event.currentTarget.dataset.iconId;
    const btnErrorId = document.getElementById("eligibility-button-error")

    this.addOrRemoveElement(event)
    
    if(this.yesRadioArrayValue.sort().toString() === this.validYesSelect.sort().toString() && this.noCount === this.noRadioArrayValue.length) {
      if(!btnErrorId.classList.contains("eligibility-error-visibility")) btnErrorId.classList.add("eligibility-error-visibility")

      this.confirmedTarget.classList.remove("disabled")
      this.checkboxTarget.checked = true
    } else {
      if(btnErrorId.classList.contains("eligibility-error-visibility")) btnErrorId.classList.remove("eligibility-error-visibility")

      this.confirmedTarget.classList.add("disabled")
    }
  }

  addOrRemoveElement(event) {
    const elementName = event.currentTarget.name;
    const elementValue = event.currentTarget.value;

    if(this.validYesSelect.includes(elementName)) {
      if(!this.yesRadioArrayValue.includes(elementName)) {
        this.yesRadioArrayValue.push(elementName)
      }

      if(elementValue === "no") {
        if(this.yesRadioArrayValue.includes(elementName)) {
          for(var i = 0; i < this.yesRadioArrayValue.length; i++) {
            if(this.yesRadioArrayValue[i] === elementName) this.yesRadioArrayValue.splice(i, 1)
          }
        }

        this.addOrRemoveEligibilityError("remove")
      } else {
        this.addOrRemoveEligibilityError("add")
      }
    } else {
      if(elementValue === "no" && !this.noRadioArrayValue.includes(elementName)) {
        this.noRadioArrayValue.push(elementName)
      }

      if(elementValue === "yes") {
        if(this.noRadioArrayValue.includes(elementName)) {
          for(var i = 0; i < this.noRadioArrayValue.length; i++) {
            if(this.noRadioArrayValue[i] === elementName) this.noRadioArrayValue.splice(i, 1)
          }
        }

        this.addOrRemoveEligibilityError("remove")
      } else {
        this.addOrRemoveEligibilityError("add")
      }
    }
  }

  addOrRemoveEligibilityError(errorType) {
    const errorSpan = document.getElementById(this.elementErrorId);
    const errorIcon = document.getElementById(this.elementIconId);

    if(errorType === "remove") {
      if(errorSpan.classList.contains("eligibility-error-visibility")) errorSpan.classList.remove("eligibility-error-visibility")
      if(errorIcon.classList.contains("eligibility-error-visibility")) errorIcon.classList.remove("eligibility-error-visibility")
    }

    if(errorType === "add") {
      if(!errorSpan.classList.contains("eligibility-error-visibility")) errorSpan.classList.add("eligibility-error-visibility")
      if(!errorIcon.classList.contains("eligibility-error-visibility")) errorIcon.classList.add("eligibility-error-visibility")
    }
  }
}
