import Dexie from "dexie";

class IndexedDBWrapper {
  constructor() {
    this.db = new Dexie("Malaria");
    this.db.version(1).stores({
      clients: "++id, value",
      consultations: "++id, value",
    });
  }

  async createNewConsultationAndPages(clientId) {
    this.db.consultations.put({
      id: clientId,
      value: {
        history: {},
        travel_history: {},
        prevention_activities: {},
        next_appointment: {},
      },
    });
  }

  async addClient(item) {
    try {
      const isNewClient = !(await this.db.clients
        .where("id")
        .equals(item.id)
        .count());

      await this.db.clients.put(item);
      if (isNewClient) {
        await this.createNewConsultationAndPages(item.id);
      }
      console.log("Client added successfully!");
    } catch (error) {
      console.error("Error adding item:", error);
    }
  }

  async getClient(id) {
    try {
      const client = await this.db.clients.get(id);
      return client;
    } catch (error) {
      console.error("Error getting client:", error);
    }
  }

  async getAllClients() {
    try {
      const clients = await this.db.clients.toArray();
      return clients;
    } catch (error) {
      console.error("Error getting all clients:", error);
    }
  }

  async deleteClient(id) {
    try {
      await this.db.clients.delete(id);
      console.log("Patient deleted successfully!");
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  }

  async getConsultation(clientId) {
    try {
      const consultation = await this.db.consultations.get(clientId);
      return consultation;
    } catch (error) {
      console.error("Error getting client:", error);
    }
  }

  async upsertConsultationPage(clientId, page, formValues) {
    try {
      const consultation = await this.db.consultations.get(clientId);
      consultation.value[page] = formValues;
      await this.db.consultations.put(consultation);
      console.log("Consultation page updated successfully!");
    } catch (error) {
      console.error("Error updating consultation page:", error);
    }
  }

  async deleteConsultation(clientId) {
    try {
      await this.db.consultations.delete(clientId);
      console.log("Consultation deleted successfully!");
    } catch (error) {
      console.error("Error deleting consultation:", error);
    }
  }

  async clearAllData() {
    try {
      await this.db.clients.clear();
      await this.db.consultations.clear();
      console.log("All data has been deleted");
    } catch (error) {
      console.error("Error clearing all data:", error);
    }
  }
}

export default IndexedDBWrapper;
