import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import IndexedDBWrapper from "./indexed_db";

export default class extends Controller {
	static targets = ["item", "note"]

	async connect() {
		this.isOfflineStorage = window.location.pathname.includes('offline_pages') || window.location.pathname.startsWith('/patients/0/consultations/0');
		this.db = new IndexedDBWrapper();
		if (this.isOfflineStorage) {
			await this.loadOfflineData();
			this.getConsultationPageController().getPatientFormController().loadOfflineData();
		}
	}

	formValues() {
		let formData = new FormData(this.element);
    let formValues = {};

    for (let [key, value] of formData.entries()) {
      if ((!key.includes("consultation_page") && !key.includes("general_note")) || formValues[key]) continue;

      formValues[key] = value ?? "";
    }

		this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
			if (!checkbox.name.includes("consultation_page")) return;

			formValues[checkbox.name] = checkbox.checked ? 'checked' : 'unchecked';
		});
    formValues.id = this.getCurrentClientId();
		return formValues;
	}

	storeOfflineData() {
		this.db.upsertConsultationPage(this.getCurrentClientId(), this.getConsultationPageController().getSelectedTab(), this.formValues()).then(() => {
			toastr.success("Data saved successfully");
		});
	}

	saveFormElement() {
		if (this.isOfflineStorage) {
			this.storeOfflineData();
		} else {
			const url = this.element.dataset.url
			Rails.ajax({
				type: 'PUT',
				url: url,
				data: new FormData(this.element)
			})
		}
	}

	async getCurrentClient() {
		return await this.db.getClient(this.getCurrentClientId());
	}

	getCurrentClientId() {
		const params = new URLSearchParams(window.location.search);
    return params.get('clientId');
	}

	getConsultationPageController() {
		return this.application.getControllerForElementAndIdentifier(document.querySelector('.consultation-page'), "consultation-page");
	}

	togglePregnantField(client) {
		const tr = document.querySelector('[name="consultation_page[history.medical.is_pregnant]"]')?.closest('tr');
		if (!tr) return;

		if (client['patient[gender]'] === "female") {
			tr.classList.remove('d-none');
		} else {
			tr.classList.add('d-none');
		}
	}

	async loadOfflineData() {
		const selectedTab = this.getConsultationPageController().getSelectedTab();
		const clientId = this.getCurrentClientId();
		const client = await this.getCurrentClient();
		this.togglePregnantField(client);
		this.db.getConsultation(clientId).then((data) => {
			// this.getConsultationPageController().setFormValues(consultation);
			const consultationPage = data.value[selectedTab];
			Object.keys(consultationPage).forEach(key => {
        if (key.includes("consultation_page") || key.includes("general_note")) {
          const element = document.querySelector(`[name="${key}"]`);
          if (element) {
            element.value = consultationPage[key];
            // if (key.includes("country")) element.dispatchEvent(new Event('change'));
            // if (key.includes("state")) {
            //   setTimeout(() => {
            //     element.value = consultation[key];
            //   }, 1000);
            // }
          }
					if (element && element.type === "checkbox") element.checked = (consultationPage[key] === "checked");
        }
      });
		});
	}
}
