import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ["box", "code", "search", "info", "address", "token", "button", "loader"]

	search() {
		const { requestType, requestInfo, requestUrl } = this.codeTarget.dataset
		const searchParam = this.codeTarget.value

		if (searchParam) {
			this.buttonTarget.hidden = true
			this.loaderTarget.hidden = false
			Rails.ajax({
				type: requestType,
				url: requestUrl,
				data: `${requestInfo}=${searchParam}`,
				success: ({ wallet_address, balance, message }) => {
					if (wallet_address || balance) {
						this.buttonTarget.hidden = false
						this.loaderTarget.hidden = true

						this.searchTarget.hidden = true
						this.infoTarget.hidden = false

						this.boxTarget.classList.add("wallet-info")
						this.addressTarget.innerHTML = wallet_address || "Not available"
						this.tokenTarget.innerHTML = balance || "Not available"
					}

					if (message) {
						this.buttonTarget.hidden = false
						this.loaderTarget.hidden = true
						toastr.options = {
							positionClass: "toastr-top-center",
							fadeIn: 300,
							fadeOut: 100,
							timeOut: 300
						}
						toastr.error(message)
					}
				}
			})
		}
	}

	return() {
		this.searchTarget.hidden = false
		this.infoTarget.hidden = true
		this.boxTarget.classList.remove("wallet-info")
		this.codeTarget.value = ""
	}
}
