import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "entries", "pagination" ]

	sortTable(event){
		const url = this.data.get("url");
		const direction = event.currentTarget.dataset.sortDirection;
		const column = event.currentTarget.dataset.sortColumn;
		const urlParams = new URLSearchParams(window.location.search);

		urlParams.delete('commit')
		urlParams.append('direction', direction)
		urlParams.append('column', column)

		Rails.ajax({
			type: 'GET',
			url: url,
			data: urlParams,
			dataType: 'json',
			success: (data) => {
        if(this.hasPaginationTarget) {
          this.paginationTarget.innerHTML = data.pagination
        }
				this.entriesTarget.innerHTML = data.entries
			}
		})

		this.setDirectionValue(event, direction)
	}

	setDirectionValue(event, direction) {
		if (direction == "asc") {
			event.currentTarget.dataset.sortDirection = "desc"
		} else {
			event.currentTarget.dataset.sortDirection = "asc"
		}
	}

}
