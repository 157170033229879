import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['tabs']

  newDose(event) {
    const { requestUrl } = event.currentTarget.dataset
    Rails.ajax({
      type: 'PUT',
      url: requestUrl,
      data: `vaccination[addDose]=true`,
      success: () => {
        // simulate click event on treatment tab
        this.tabsTarget.click()
      }
    })
  }
}
