import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['button', 'spanbtn', 'loader', 'code']

  disableBtn(btn) {
    const { requestCount, requestCountLimit } = btn.dataset
    const currentCount = parseInt(requestCount)
    const countLimit = parseInt(requestCountLimit)

    btn.disabled = true
    if (currentCount < countLimit) {
      btn.dataset.requestCount = currentCount + 1
      setTimeout(()=> {
        btn.disabled = false
      }, 30000)
    }
  }

  requestCode() {
    this.disableBtn(this.buttonTarget)

    const { userKey, requestUrl } = this.buttonTarget.dataset
    Rails.ajax({
      type: 'GET',
      url: requestUrl,
      data: `key=${userKey}`,
      success: ({ message }) => {
        toastr.options = {
          positionClass: "toastr-top-center",
          fadeIn: 300,
          fadeOut: 100,
          timeOut: 300
        }
        toastr.success(message)
      }
    })
  }

  verifyCode() {
    const { requestUrl } = this.codeTarget.dataset
    const smsCode = this.codeTarget.value

    this.loaderTarget.hidden = false
    this.spanbtnTarget.hidden = true
    
    Rails.ajax({
      type: 'GET',
      url: requestUrl,
      data: `code=${smsCode}`,
      success: ({ url }) => {
        console.log("URL:", url)
        window.location = url
      },
      error: ({ message }) => {
        toastr.options = {
          positionClass: "toastr-top-center",
          fadeIn: 300,
          fadeOut: 1000,
          timeOut: 300
        }
        toastr.error(message)
      }
    })
  }
}