import { Controller } from 'stimulus';

export default class extends Controller {

  selectRow(event) {
    const clickedTarget = event.target;
    const targetName = clickedTarget.nodeName.toLowerCase();
    const parentNode = (targetName == 'i') ? clickedTarget.parentNode.parentNode : clickedTarget.parentNode;

    window.location = parentNode.dataset.url;
  }
}
