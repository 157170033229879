if (navigator.serviceWorker && window.location.pathname !== '/users/sign_in') {
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then(() => navigator.serviceWorker.ready)
    .then((registration) => {
      if ("SyncManager" in window) {
        registration.sync.register("sync-forms");
      } else {
        console.log("This browser does not support background sync.");
      }
    })
    .then(() => console.log("[Companion]", "Service worker registered!"));
}
// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//   for(let registration of registrations) {
//     registration.unregister();
//   }
// });
