import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	save(event) {
		const url = this.element.dataset.url
		const { field, vaccinationIndex } = event.currentTarget.dataset
		const value = event.currentTarget.value
		Rails.ajax({
			type: 'PUT',
			url: url,
			data: `vaccination[index]=${vaccinationIndex}&vaccination[field]=${field}&vaccination[value]=${value}`
		})
	}
}
