import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["completeBtn"]

  saveAndComplete() {
    const { requestUrl, requestType, requestInfo, confirmMessage } = this.completeBtnTarget.dataset

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary confirm-button',
        cancelButton: 'btn btn-primary cancel-button',
        container: 'dialog-container',
        popup: 'dialog-box'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      text: confirmMessage,
      showCancelButton: true,
      confirmButtonText: 'OK',
      reverseButtons: true,
      target: '#content-container'
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          type: requestType,
          url: requestUrl,
          data: requestInfo
        })
      }
    })
  }
}
