import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["phone", "error"]

	iti = intlTelInput(this.phoneTarget, {
		initialCountry: "ZM",
		utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js",
		hiddenInput: "phone"
	})

	connect() {
		this.iti.getNumber()
		this.handleCountryCodeDisplay()
		this.handleCountryCodeChange()
	}


	handleCountryCodeDisplay() {
		const codeElement = this.element.getElementsByClassName("iti__selected-flag")[0]
		codeElement.innerHTML = null

		const selectedCountryCode = this.iti.getSelectedCountryData().dialCode

		const code = document.createElement("span")
		code.innerHTML = `+${selectedCountryCode}`

		code.classList.add("iti__selected-dial-code")
		codeElement.appendChild(code)
	}

	handleCountryCodeChange() {
		this.phoneTarget.addEventListener("countrychange", () => {
			this.handleCountryCodeDisplay()
		})
	}

	validatePhoneNumber() {
		const target = this.phoneTarget
		const errorContainer = this.errorTarget
		const targetWrapper = target.parentElement.parentElement
		const targetWrapperError = targetWrapper.getElementsByClassName("error")[0]

		errorContainer.innerHTML = ""
		targetWrapper.classList.remove("field_with_errors")

		if (target.value.trim()) {
			if (targetWrapperError) {
				targetWrapperError.innerHTML = ""
			}

			if (!this.iti.isValidNumber()) {
				targetWrapper.classList.add("field_with_errors")
				errorContainer.innerHTML = "This phone number is invalid."
			}
		}
	}
}
