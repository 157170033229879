import { Controller } from "stimulus";
import IndexedDBWrapper from "./indexed_db";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["tableBody", "syncIcon", "syncButton"];

  connect() {
    this.db = new IndexedDBWrapper();
    this.showOfflineClients();
    this.toggleSyncButton();
  }

  clientHTML(client) {
    const clientId = client["id"];
    return `
      <tr class="my-auto table-row clickable" data-action="click->table-link#selectRow" data-url="/consultations/offline_table?clientId=${clientId}" id="${clientId}" data-is-offline-storage="true">
        <td class="left-radius"></td>
        <td>${clientId} (Offline Storage)</td>
        <td>${client["patient[first_name]"]}</td>
        <td>${client["patient[last_name]"]}</td>
        <td></td>
        <td class="right-radius">
          <i class="fas fa-arrow-right"></i>
        </td>
      </tr>
    `;
  }

  showOfflineClients() {
    this.db.getAllClients().then((clients) => {
      clients.forEach((client) => {
        if (document.getElementById(client.id)) return;
        this.tableBodyTarget.insertAdjacentHTML(
          "afterbegin",
          this.clientHTML(client)
        );
      });
    });
  }

  async syncClients(clients) {
    const { requestUrl } = this.syncButtonTarget.dataset;
    const formData = new FormData();
    const tmpHash = {};
    for (let index = 0; index < clients.length; index++) {
      const client = clients[index];
      for (let key in client) {
        formData.append(`clients[${index}][${key}]`, client[key]);
      }
      const consultation = (await this.db.getConsultation(client.id)).value;
      formData.append(`consultations[${index}][id]`, client.id);
      [
        "history",
        "travel_history",
        "prevention_activities",
        "next_appointment",
      ].forEach((consultationPageKey) => {
        const consultationPage = consultation[consultationPageKey];
        for (let key in consultationPage) {
          formData.append(
            `consultations[${index}][${consultationPageKey}][${key}]`,
            consultationPage[key]
          );
        }
      });
    }
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    Rails.ajax({
      url: requestUrl,
      type: "POST",
      data: formData,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      success: (data) => {
        toastr.success("Client synced successfully");
        data.successful_client_ids.forEach((clientId) => {
          this.db.deleteClient(clientId);
          this.db.deleteConsultation(clientId);
        });
      },
      error: (error) => {
        toastr.error("Error syncing client", error);
      },
      complete: () => {
        setTimeout(() => {
          this.syncIconTarget.classList.remove("fa-spin");
          location.reload();
        }, 1000);
      },
    });
  }

  async syncOfflineData() {
    this.syncIconTarget.classList.add("fa-spin");
    const clients = await this.db.getAllClients();
    if (clients.length === 0) {
      toastr.error("No offline data to sync");
      setTimeout(() => {
        this.syncIconTarget.classList.remove("fa-spin");
      }, 1000);
      return;
    } else {
      await this.syncClients(clients);
    }
  }

  toggleSyncButton() {
    if (!navigator.onLine) {
      this.syncButtonTarget.classList.add("d-none");
      return;
    }

    this.db.getAllClients().then((clients) => {
      if (clients.length > 0) {
        this.syncButtonTarget.classList.remove("d-none");
      } else {
        this.syncButtonTarget.classList.add("d-none");
      }
    });
  }
}
