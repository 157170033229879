import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ "charCount", "note", "charCount2", "note2"]

  initialize() {
    this.updateCount()
  }
  updateCount() {
    if (this.hasCharCountTarget) {
      this.charCountTarget.textContent = this.noteTarget.value.length
    }

    if (this.hasCharCount2Target) {
      this.charCount2Target.textContent = this.note2Target.value.length
    }
  }
}
