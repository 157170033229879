import { Controller } from 'stimulus';
import IndexedDBWrapper from "./indexed_db";

export default class extends Controller {
  static targets = ["title", "backlink"]

  connect() {
    this.updateTitle();
    this.updateBacklink();
  }

  updateTitle() {
    const params = new URLSearchParams(window.location.search);
    const clientId = params.get('clientId');
    if (!clientId) return;

    const db = new IndexedDBWrapper();
    db.getClient(clientId).then((client) => {
      const fullname = `${client["patient[first_name]"]} ${client["patient[last_name]"]}`;
      const title = `Edit client details - ${fullname}`;
      this.titleTarget.innerHTML = title;
    });
  }

  updateBacklink() {
    if (!this.hasBacklinkTarget) return;

    if (navigator.onLine) {
      this.backlinkTarget.href = `/patients`;
    } else {
      this.backlinkTarget.href = `/patients/offline_view`;
    }
  }
}
