import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ 'select', 'label', 'note' ]

  connect() {
    this.showHideDescription()
  }

	saveFormElement() {
    const form = this.element.closest('form');
    
    this.showHideDescription()
    
    Rails.ajax({
      type: 'PUT',
      dataType: 'json',
      url: this.element.dataset.url,
      data: new FormData(form)
    })
	}

  showHideDescription() {
    const selectValue = this.selectTarget.value;

    if (selectValue == 'Improved') {
      this.labelTarget.classList.remove('d-none')
      this.noteTarget.classList.remove('d-none')
    } else {
      this.labelTarget.classList.add('d-none')
      this.noteTarget.classList.add('d-none')
    }
  }
}
