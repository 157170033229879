import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = [ "select" ]
  
  connect() {
    const isDisabled = this.selectTarget.dataset.disableChecker
  
    if (isDisabled == "true") {
      this.element.disabled = 'disabled'
    }
  }

  save(){
    Rails.ajax({
      type: this.element.dataset.requestType,
      url: this.element.dataset.requestUrl,
      dataType: 'json',
      data: `${this.element.dataset.requestInfo}=${this.selectTarget.value}`,
      success: (data) => {
        const flashDiv = document.createElement('div');
        flashDiv.dataset.controller='flash'
        flashDiv.dataset.flashKey = data.flash_key
        flashDiv.dataset.flashValue = data.flash_value
        this.selectTarget.insertAdjacentElement("beforebegin", flashDiv)
      }
    })
  }
}
