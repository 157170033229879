import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['date', 'reminder']

  connect() {
    toastr.options = { positionClass: "toastr-top-center" };
    this.disableButton()
  }

  // Accepts button action for SMS Reminder and Vaccination Link
  sendReminder(event) {
    event.preventDefault()

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: this.reminderTarget.dataset.url,
      success: ({ success, message }) => {
        success ? toastr.success(message) : toastr.error(message)
      }
    })
  }

  // disable send reminder button when no date selected
  disableButton() {
    for (let i = 0; i < this.dateTargets.length; i++) {
      this.reminderTargets[i].disabled = false
      if (this.dateTargets[i].value === '') {
        this.reminderTargets[i].disabled = true
      }
    }
  }
}
