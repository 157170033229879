import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["select", "date", "clinic"]

	initialize() {
		this.display()
	}

	display() {
		const selectedValue = this.selectTarget.value
		const { selection } = this.selectTarget.dataset
		const parsedSelection = JSON.parse(selection)

		// First case represents reason selection option2
		// Second case represents reason selection option3
		// Third case represents reason selection option4
		// Default case represents reason selection option1 and none selected
		switch (selectedValue) {
			case parsedSelection[1]:
				this.displayChange(false, true, 0)
				break;
			case parsedSelection[2]:
				this.displayChange(false, true, 1)
				break;
			case parsedSelection[3]:
				this.displayChange(false, false, 2)
				break;
			default:
				this.displayChange(true, true)
				break;
		}
	}

	handleDisplayChange() {
		this.dateTarget.getElementsByTagName("input")[0].value = null
		this.clinicTarget.getElementsByTagName("input")[0].value = null

		this.display()
	}

	displayChange(condition1, condition2, element) {
		const { label } = this.dateTarget.dataset
		const parsedLabel = JSON.parse(label)

		this.dateTarget.hidden = condition1
		this.clinicTarget.hidden = condition2

		this.dateTarget.getElementsByTagName("label")[0].innerHTML = parsedLabel[element]
	}
}
