import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["viewButton", "createButton"]
  connect() {
    this.setupOfflineLinks();
  }

  setupOfflineLinks() {
    if (navigator.onLine) {
      this.viewButtonTarget.href = "/patients";
      this.createButtonTarget.href = "/patients/new";
    } else {
      this.viewButtonTarget.href = "/patients/offline_view";
      this.createButtonTarget.href = "/patients/0/consultations/0?is_offline=true&new_client=true";
    }

  }
}
