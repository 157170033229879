import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ["country", "state"]

	initialize() {
		this.fetchStates()
	}

	handleStatesChange() {
		this.stateTarget.innerHTML = ""
		const option = document.createElement("option")
		option.text = "Select"
		option.value = ""
		this.stateTarget.appendChild(option)

		this.fetchStates()
	}

	fetchStates() {
		if (this.hasCountryTarget) {
			const selectedCountry = this.countryTarget.value
			const { selectedState } = this.stateTarget.dataset

			if (selectedCountry) {
				Rails.ajax({
					type: 'GET',
					url: '/patients/fetch_states?country=' + selectedCountry,
					success: ({ data }) => {
						Object.entries(data).forEach(([key, value]) => {
							const option = document.createElement("option")
							option.text = value
							option.value = key
							option.selected = selectedState === key

							this.stateTarget.appendChild(option)
						})
					}
				})
			}
		}
	}
}
